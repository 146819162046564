import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridInitialState,
  GridValueGetterParams,
  gridVisibleSortedRowEntriesSelector,
} from '@mui/x-data-grid';
import { GridStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { RankingResultat } from 'src/models/Stevne';
import { DateFormat, formatFromApiDate, parseDate } from 'src/utils/date';
import { showNullableNumber } from 'src/utils/table';

type RankingTableProps = {
  resultater: RankingResultat[];
  isLoadingResultater: boolean;
  onRowsChange: (resultater: RankingResultat[]) => void;
};

const columns: GridColDef<RankingResultat>[] = [
  {
    field: 'navn',
    headerName: 'Navn',
    minWidth: 140,
  },
  {
    field: 'klubb',
    headerName: 'Klubb',
    valueGetter: (params: GridValueGetterParams) => params.row.klubbNavn,
  },
  {
    field: 'foedselsdato',
    headerName: 'Fødselsdato',
    width: 100,
    valueGetter: (params: GridValueGetterParams) =>
      formatFromApiDate(params.row.foedselsdato),
    sortComparator: (a, b) => {
      const aValue = parseDate(a, DateFormat).getTime();
      const bValue = parseDate(b, DateFormat).getTime();

      return aValue - bValue;
    },
  },
  {
    field: 'kroppsvekt',
    headerName: 'Kroppsvekt',
    width: 100,
  },
  {
    field: 'vektklasse',
    headerName: 'Vektklasse',
    width: 100,
  },
  {
    field: 'kategori',
    headerName: 'Kategori',
    width: 80,
  },
  {
    field: 'stevnetype',
    headerName: 'Stevnetype',
    width: 140,
  },
  {
    field: 'stevnested',
    headerName: 'Sted',
    width: 140,
  },
  {
    field: 'dato',
    headerName: 'Stevnedato',
    width: 100,
    valueGetter: (params: GridValueGetterParams) =>
      formatFromApiDate(params.row.dato),
    sortComparator: (a, b) => {
      const aValue = parseDate(a, DateFormat).getTime();
      const bValue = parseDate(b, DateFormat).getTime();

      return aValue - bValue;
    },
  },
  {
    field: 'besteRykk',
    headerName: 'Rykk',
    type: 'number',
    width: 70,
    valueGetter: (params: GridValueGetterParams) =>
      showNullableNumber(params.row.besteRykk),
  },
  {
    field: 'besteStot',
    headerName: 'Støt',
    type: 'number',
    width: 70,
    valueGetter: (params: GridValueGetterParams) =>
      showNullableNumber(params.row.besteStot),
  },
  {
    field: 'total',
    headerName: 'Total',
    type: 'number',
    width: 70,
    valueGetter: (params: GridValueGetterParams) =>
      showNullableNumber(params.row.total),
  },
  {
    field: 'poeng',
    headerName: 'Poeng',
    type: 'number',
    width: 100,
    valueGetter: (params: GridValueGetterParams) =>
      showNullableNumber(params.row.poeng),
  },
  {
    field: 'veteranpoeng',
    headerName: 'Veteranpoeng',
    type: 'number',
    width: 120,
    valueGetter: (params: GridValueGetterParams) =>
      showNullableNumber(params.row.veteranpoeng),
    sortComparator: (a, b) => {
      const aValue = a === '-' ? 0 : a;
      const bValue = b === '-' ? 0 : b;

      return aValue - bValue;
    },
  },
];

export function RankingTable({
  resultater,
  isLoadingResultater,
  onRowsChange,
}: RankingTableProps) {
  const handleStateChange = (state: GridStateCommunity) => {
    const visibleResultater = gridVisibleSortedRowEntriesSelector(state).map(
      x => x.model as RankingResultat
    );

    onRowsChange(visibleResultater);
  };

  const initialState: GridInitialState = {
    sorting: {
      sortModel: [{ field: 'poeng', sort: 'desc' }],
    },
  };

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        getRowId={row => `${row.navn}-${row.dato}`}
        initialState={initialState}
        autoHeight
        rows={resultater}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        onStateChange={handleStateChange}
        disableColumnMenu
        disableSelectionOnClick
        loading={isLoadingResultater}
      />
    </Box>
  );
}
