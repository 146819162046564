import { Klubb } from 'src/models/Klubb';
import { usePublicSWR } from './usePublicSWR';
import { sortByProperty } from 'src/utils/sort';

export const PUBLIC_KLUBBER_URI = 'public/klubber/';

export function usePublicKlubber() {
  const { data, error } = usePublicSWR<Klubb[]>(PUBLIC_KLUBBER_URI);

  return {
    klubber: sortByProperty(data || [], 'navn'),
    isLoadingKlubber: !data && !error,
    error: error,
  };
}
