import { KategoriType } from 'src/models/Stevne';
import { subMonths } from 'date-fns';

export type RankingSearchFormState = {
  fromDate: Date;
  toDate: Date;
  genderOption: GenderOption;
  kategorier: KategoriType[];
  vektklasse: Nullable<string>;
  klubbId: Nullable<number>;
  personId: Nullable<number>;
  onlyBestResultatForPerson: boolean;
};

export const DefaultRankingSearchFormState: RankingSearchFormState = {
  fromDate: subMonths(new Date(), 2),
  toDate: new Date(),
  genderOption: 'ALL',
  kategorier: ['S', 'J', 'U', 'V'],
  vektklasse: null,
  klubbId: null,
  personId: null,
  onlyBestResultatForPerson: false,
};

export type GenderOption = 'ALL' | 'M' | 'K';
