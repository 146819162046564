import { Box, Divider } from '@mui/material';
import { useState } from 'react';
import { ResultaterApi } from 'src/ajax/resultater.api';
import { PublicPage } from 'src/components/shared/Page/PublicPage';
import { RankingSearchForm } from 'src/components/shared/RankingSearch/RankingSearchForm';
import { RankingSearchFormState } from 'src/components/shared/RankingSearch/RankingSearchForm.types';
import { Header1 } from 'src/components/shared/Typography/Header1';
import { PublicRankingResultat } from 'src/models/Stevne';
import { PublicRankingTable } from './PublicRankingTable';
import { usePublicKlubber } from 'src/ajax/hooks/usePublicKlubber';

export function PublicRankingPage() {
  const [rankingResultater, setRankingResultater] = useState<
    PublicRankingResultat[]
  >([]);
  const { klubber } = usePublicKlubber();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (state: RankingSearchFormState) => {
    setIsLoading(true);
    let response;

    response = await ResultaterApi.searchPublicResultater(state);

    if (response && response.ok) {
      setRankingResultater(response.data);
    }

    setIsLoading(false);
  };

  return (
    <PublicPage>
      <Header1 className="mb-8">Resultater</Header1>

      <section>
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          flexWrap="wrap"
          className="mb-4 gap-4"
        >
          <RankingSearchForm
            klubber={klubber}
            submitDefaultStateOnMount
            onSubmit={handleSubmit}
          />
        </Box>

        <Divider className="my-4" />

        <PublicRankingTable
          resultater={rankingResultater}
          isLoading={isLoading}
        />
      </section>
    </PublicPage>
  );
}
