import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Divider } from '@mui/material';
import { useState } from 'react';
import { ReportsApi } from 'src/ajax/reports.api';
import { ResultaterApi } from 'src/ajax/resultater.api';
import { RankingSearchForm } from 'src/components/shared/RankingSearch/RankingSearchForm';
import { RankingSearchFormState } from 'src/components/shared/RankingSearch/RankingSearchForm.types';
import { useAuth } from 'src/context/AuthContext';
import { useKlubb } from 'src/context/KlubbContext';
import { RankingResultat } from 'src/models/Stevne';
import { isKlubbAdmin, isNvfAdmin } from 'src/models/User';
import { downloadFileFromResponse } from 'src/utils/file.utils';
import { RankingTable } from './RankingTable';
import { usePersoner } from 'src/ajax/hooks/usePersoner';

export function RankingPage() {
  const { user } = useAuth();
  const { klubb, klubber } = useKlubb();
  const {personer} = usePersoner();

  const [resultater, setResultater] = useState<RankingResultat[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [visibleResultater, setVisibleResultater] = useState<RankingResultat[]>(
    []
  );

  const handleSubmit = async (state: RankingSearchFormState) => {
    setIsLoading(true);
    let response;

    if (isNvfAdmin(user)) {
      response = await ResultaterApi.searchResultater(state);
    }

    if (isKlubbAdmin(user) && klubb) {
      response = await ResultaterApi.searchKlubbResultater(klubb.id, state);
    }

    if (response && response.ok) {
      setResultater(response.data);
    }

    setIsLoading(false);
  };

  const handleDownloadResultater = async () => {
    const response = await ReportsApi.downloadResultater(visibleResultater);
    if (response.ok) {
      downloadFileFromResponse(response.axiosResponse);
    }
  };

  return (
    <section>
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        flexWrap="wrap"
        className="mb-4 gap-4"
      >
        <RankingSearchForm
          klubber={klubber}
          personer={personer}
          lockedKlubb={klubb || undefined}
          submitDefaultStateOnMount
          onSubmit={handleSubmit}
        />
        <Button
          variant="outlined"
          onClick={handleDownloadResultater}
          endIcon={<DownloadIcon />}
        >
          Last ned
        </Button>
      </Box>

      <Divider className="my-4" />

      <RankingTable
        resultater={resultater}
        isLoadingResultater={isLoading}
        onRowsChange={setVisibleResultater}
      />
    </section>
  );
}
