import { Autocomplete, TextField } from '@mui/material';
import {
  Controller,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

export type FormSelectOption = {
  id: string | number;
  label: string;
};

type FormSelectProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string;
  options: FormSelectOption[];
  disabled?: boolean;
  disabledText?: string;
};

export function FormSelect<T extends FieldValues>({
  name,
  control,
  label,
  options,
  disabled,
  disabledText,
  ...rest
}: FormSelectProps<T>) {
  const { field, fieldState } = useController({
    name,
    control,
    ...rest,
  });

  const helperText = disabled ? disabledText : null;
  const error = fieldState.error?.message;

  const { ref, ...fieldRest } = field;

  return (
    <Controller
      {...fieldRest}
      control={control}
      render={({ field: renderField }) => (
        <Autocomplete
          options={options}
          value={options.find(option => option.id === renderField.value) || null}
          onChange={(_, newValue) => {
            renderField.onChange(newValue?.id || null);
          }}
          disabled={disabled}
          renderInput={params => (
            <TextField
              {...params}
              ref={ref}
              label={label}
              error={!!error}
              helperText={error || helperText}
            />
          )}
        />
      )}
    />
  );
}
